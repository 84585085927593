<template>
  <div :class="$style.contacts">
    <div :class="$style.wrapper">
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="submitForm()"
        >Сохранить контент</el-button
      >
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
      :class="$style.form"
    >
      <el-form-item label="Название карточки" prop="cardName">
        <el-input v-model="form.cardName" />
      </el-form-item>
      <el-form-item label="Улица" prop="street">
        <el-input v-model="form.street" />
      </el-form-item>
      <el-form-item label="Город" prop="city">
        <el-input v-model="form.city" />
      </el-form-item>
      <el-form-item label="Номер дома">
        <el-input v-model="form.office" />
      </el-form-item>
      <el-form-item label="Комментарий">
        <el-input
          v-model="form.comment"
          :rows="2"
          type="textarea"
          placeholder="Введите комментарий"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

export default {
  components: {},
  mixins: [notifications],
  data() {
    return {
      form: {
        cardName: '',
        street: '',
        city: '',
        office: '',
        comment: ''
      },
      rules: {
        cardName: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        street: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        city: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ]
      }
    }
  },

  methods: {
    async submitForm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })

          const data = {
            ...this.form
          }

          const result =
            await delivery.AddwineCore.DealersActions.createDealerDeliveryAddress(
              this.$route.params.id,
              data
            )
          loading.close()

          if (result.error) return

          this.showNotification('Адрес доставки успешно создан', 'success')
          this.$router.push(
            `/sellers/dealers/${this.$route.params.id}/delivery-addresses`
          )
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.contacts {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }
  .icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .form {
    h2 {
      text-align: center;
      margin-bottom: 3rem;
    }
    h3 {
      margin-bottom: 1rem;
    }
  }
}
</style>
